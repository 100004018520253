<template>
    <Dialog modal header="Datos adicionales" :style="{width: '800px'}">  
        <div class="card card-default card-tabs">
            <div class="card-header p-1 pt-1">
                <ul class="nav nav-pills" ref="tabHeaders">
                    <li v-for="(info, index) in Object.entries(adicionales)" :key="info[0]" class="nav-item">
                        <a class="nav-link" :href="'#'+info[0]" @click.prevent="cambiarTab(index)"
                        :class="{ 'active': index === activeTab }">
                            {{ info[0] }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <br>
        <div class="tab-content" ref="tabContents">
            <div v-for="(info, index) in Object.entries(adicionales)" :key="info[0]" class="tab-pane container-grid"
                :id="info[0]" :class="{ 'active': index === activeTab }">  
                <div class="container">
                    <div v-for="(campo, index2) in info[1]" :key="campo.id_campo" class="form">
                        <label :for="'value_' + index" class="font-semibold">{{ campo.nombre_campo }}</label>
                        
                        <input v-model="respuestasCampos[index][index2]" v-if="campo.tipo_campo === 'texto'" type="text" :id="'value_' + index"/>

                        <v-select v-model="respuestasCampos[index][index2]" v-if="campo.tipo_campo === 'valores'" :options="getValores(campo.valores_campo)" placeholder="Selecciona un valor"></v-select>

                        <input v-model="respuestasCampos[index][index2]" v-if="campo.tipo_campo === 'numero'" type="number" :id="'value_' + index"/>

                        <textarea v-model="respuestasCampos[index][index2]" v-if="campo.tipo_campo === 'multilinea'" name="tt" rows="6" cols="30" :id="'value_' + index"></textarea>

                        <Editor v-model="respuestasCampos[index][index2]" v-if="campo.tipo_campo === 'formateado'" editorStyle="height: 160px" :id="'value_' + index"/>

                        <label v-if="campo.tipo_campo === 'formula'" :id="'value_' + index">{{ respuestasCampos[index][index2] }}</label>
                    </div>
                </div> 
            </div>
        </div>
        <div style="display: flex; border-top: 1px solid grey; margin-top: 10px; justify-content: end;">
            <button class="btn btn-light btn-sm" style="border: 1px solid; margin-top: 15px;" @click="guardarDatos">Guardar</button>
        </div>       
    </Dialog>
</template>

<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
import $ from 'jquery'; // Asegúrate de tener jQuery disponible
import Editor from 'primevue/editor';
export default {
    props: ['idservicio', 'gestion'],
    components: {
        Dialog,
        Editor,
    },
    data() {
        return {
            adicionales: [],
            valoresPorDefecto: {},
            activeTab: 0, // Índice de la pestaña activa
            respuestas: [],
            respuestasCampos:[[]],
        };
    },
    methods: {
        async cargarAdicionales() {
            console.log('gestion', this.gestion);
            this.adicionales = this.gestion.datos_adicionales.servicios;
            this.respuestas = this.gestion.datos_adicionales.respuestas;

            this.rellenarRespuestas();
            // Asegúrate de activar la primera pestaña al cargar los datos
            this.$nextTick(() => {
                this.activarPrimerTab();
            });
        },
        cambiarTab(index) {
            this.activeTab = index;
            this.$nextTick(() => {
                this.mostrarTab(index);
            });
        },
        getValores(serialized){
            const regex = /i:\d+;s:\d+:"(.*?)";/g;
            let match;
            const result = [];

            // Usar un bucle para encontrar todas las coincidencias y extraer los valores
            while ((match = regex.exec(serialized)) !== null) {
                result.push(match[1] );
            }
            return result;
        },
        rellenarRespuestas() {
            if (!this.respuestas) {
                // Inicializa respuestasCampos con la misma longitud que las claves de adicionales, cada uno con un sub-array vacío.
                this.respuestasCampos = Object.keys(this.adicionales).map(() => []);  

                // Rellena el array bidimensional con strings vacíos.
                Object.entries(this.adicionales).forEach(([key, campos], i) => {
                    console.log(key);
                    this.respuestasCampos[i] = campos.map(() => "");  // Rellenar cada campo con un string vacío.
                });
            } else {
                this.respuestasCampos = Object.keys(this.adicionales).map(() => []);  // Inicializa el array bidimensional

                var ids = Object.keys(this.adicionales);  // Obtén las claves de los adicionales.
                for (var ii = 0; ii < ids.length; ii++) {
                    var idDato = ids[ii];
                    var campos = this.adicionales[idDato];
                    
                    // Usa map para recorrer cada campo y asignar la respuesta correspondiente.
                    this.respuestasCampos[ii] = campos.map((campo, jj) => {
                        console.log(jj);
                        const id = campo.id_campo;
                        return this.respuestas[id] ? this.respuestas[id].valor_campo : "";  // Asigna la respuesta o un string vacío.
                    });
                }
            }
        },

        mostrarTab(index) {
            // Usa refs para asegurarte de que solo estás afectando los elementos de este componente
            const $tabs = $(this.$refs.tabHeaders).find('.nav-link');
            const $panes = $(this.$refs.tabContents).find('.tab-pane');

            $tabs.removeClass('active').eq(index).addClass('active');
            $panes.removeClass('active').eq(index).addClass('active');
        },
        activarPrimerTab() {
            this.mostrarTab(this.activeTab);
        },
        async guardarDatos(){
            const api = new PwgsApi();
            var ids = Object.keys(this.adicionales);
            console.log('llega', ids);
            var i = 0;
            for(let adicional of Object.entries(this.adicionales)){
                for(var j in adicional[1]){
                    var id = adicional[1][j].id_campo;
                    var valor = this.respuestasCampos[i][j];
                    var body = {'seccion': 'servicios', 'id_seccion':this.idservicio, 'id_campo':id, 'valor_campo':valor};
                    const resp = await api.put('servicios/' + this.idservicio + '/campos', body);
                    console.log('campoGuadado', resp);
                }
                i++;
            }
            alert('Campos guardados.')
        },
    },
    watch: {
        gestion() {
            this.valoresPorDefecto = {};
            this.adicionales = [];
            this.cargarAdicionales();
        },
    },
};
</script>

<style scoped>
.container{
    display: grid;
    height: 400px;
    overflow: auto;
}
.form{
    margin-bottom: 30px;
}
.form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold; 
}

.form textarea {
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}
.form editor{
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.form select{
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin-bottom: 50px;
}

.form input{
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}
.btns{
    display: flex;
    justify-content: end;
    gap:15px;
    padding-bottom: 10px;
}
.btn-size:hover{
    background-color:rgb(163, 163, 163);
}
</style>